<template>
    <div class="px-3 py-2">
        <v-form
            ref="form"
            lazy-validation
        >
        <b-sidebar id="update_customer" aria-labelledby="sidebar-no-header-title" width="50rem" no-header backdrop style="direction:ltr" right title="تعديل عميل" shadow >
        <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span>{{$parent.lang.update_customer}}</span>
                </div>
                <div @click="hide" id="hidaddcus" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>{{$parent.lang.close}}</span>
            </div>
        </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <v-row>
                <v-col cols="12" md="2" sm="12">
                    <v-text-field
                        :label='$parent.lang.customerid'
                        v-model="customers.customerid"
                        readonly
                        >{{ customers.mobile }}</v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <v-text-field
                        :label='$parent.lang.mobile'
                        v-model="customers.mobile"
                        type="number"
                        readonly
                        >{{ customers.mobile }}</v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <v-text-field
                        :label="$parent.lang.additional_mobile"
                        v-model="customers.mobile1"
                        type="number"
                        >{{ customers.mobile1 }}</v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                    <v-text-field
                        :label="$parent.lang.customer_name"
                        v-model="customers.full_name"
                        :rules="nameRules"
                        >{{ customers.full_name }}</v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                    <v-text-field
                        :label="$parent.lang.company_name"
                        v-model="customers.company_name"
                        >{{ customers.company_name }}</v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                    <v-text-field
                        :label="$parent.lang.company_vatid"
                        v-model="customers.company_vatid"
                        >{{ customers.company_vatid }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <v-text-field
                        :label="$parent.lang.building_no"
                        v-model="customers.building_no"
                        >{{ customers.building_no }}</v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                    <v-text-field
                        :label="$parent.lang.street_name"
                        v-model="customers.street_name"
                        >{{ customers.street_name }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <v-text-field
                        :label="$parent.lang.branch_number"
                        v-model="customers.branch_number"
                        >{{ customers.branch_number }}</v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                    <v-text-field
                        :label="$parent.lang.district"
                        v-model="customers.district"
                        >{{ customers.district }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <v-text-field
                        :label="$parent.lang.zipcode"
                        v-model="customers.zipcode"
                        >{{ customers.zipcode }}</v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                    <v-text-field
                        :label="$parent.lang.city"
                        v-model="customers.city"
                        >{{ customers.city }}</v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                    <v-text-field
                        :label="$parent.lang.address"
                        v-model="customers.address"
                        >{{ customers.address }}</v-text-field>
                </v-col>
            </v-row>
        </div>
        </template>
        <template #footer="" class="shadow">
                <!-- <small :style="`color:red;margin-`+$parent.lang.lalgin+`:10px`">{{$parent.lang.update_all_notes}}</small> -->
            <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" @click="addCustomer(0);" variant="success" class="ma-2" style="width:100px;">{{$parent.lang.update}}</b-button>
                <!-- <b-button type="button" @click="addCustomer(1);" variant="success" class="ma-2" style="width:100px;">{{$parent.lang.update_all}}</b-button> -->
            </div>
      </template>
        </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
      </div>
</template>


<script>
import axios from 'axios';
export default{
    data: () => ({
        customers:{
            id: '',
            mobile: '',
            mobile1: '',
            full_name: '',
            company_name: '',
            company_vatid: '',
            building_no: '',
            street_name: '',
            branch_number: '',
            district: '',
            zipcode: '',
            city:'',
            address:'',
        },
        
    }),
    computed: {
        mobileRules: function() {
            return [
                v => !!v || this.$parent.lang.required_field,
                v => (v && v.length <= 10) || this.$parent.lang.mobil_10_digits,
            ]
        },
        nameRules: function() {
            return [
                v => !!v || this.$parent.lang.required_field,
            ]
        }
    }, 
    methods: {
        
        addCustomer(id){
            // // console.log();
            if(!this.$refs.form.validate()){
                return false;
            }
            const post = new FormData();
            post.append("type" , "updateCustomer");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[id]",this.customers.id)
            post.append("data[mobile]",this.customers.mobile)
            post.append("data[mobile1]",this.customers.mobile1)
            post.append("data[full_name]",this.customers.full_name)
            post.append("data[company_name]",this.customers.company_name);
            post.append("data[company_vatid]",this.customers.company_vatid);
            post.append("data[building_no]",this.customers.building_no);
            post.append("data[street_name]",this.customers.street_name);
            post.append("data[branch_number]",this.customers.branch_number);
            post.append("data[district]",this.customers.district);
            post.append("data[zipcode]",this.customers.zipcode);
            post.append("data[city]",this.customers.city);
            post.append("data[address]",this.customers.address);
            post.append("updatetype",id);
            axios.post(
                this.$SAMCOTEC.r_path , post
            ).then((response) => {
                var res = response.data;
                if(res.error.number == 200){
                    if (this.$snotify.notifications.length > 0) {
                        this.$snotify.notifications.forEach(notification => {
                            this.$snotify.remove(notification.id)
                        });
                    }
                    this.$snotify.success(this.$parent.lang.customer_addedd, '', {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                    
                    this.$parent.getClients();
                    document.getElementById('hidaddcus').click();
                }else{
                    this.$snotify.error(res.error.customer_updated, '', {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                }
            })
        }
    },
}
</script>